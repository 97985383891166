document.addEventListener("DOMContentLoaded", function(){
  AOS.init();

  var swiper = new Swiper('.swiper-container', {
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    spaceBetween: 30,
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: true,
    centeredSlides: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  });

  $('nav#menu').mmenu({
    drag: true,
    navbar: {
      title: "選單"
    },
    pageScroll: {
      scroll: true,
      update: true
    },
    'extensions': [
      'theme-dark',
      'fx-menu-fade',
      'fx-listitems-slide'
    ]
  }, {
    classNames: {
      fixedElements: {
        fixed: 'fix',
        sticky: 'is-sticky'
      }
    }
  })
  $('#major-sticky').sticky({topSpacing: 72})
  $('#major-sticky').on('sticky-end', function () { $(this).parent('.sticky-wrapper').css({height: 'auto'}) }) // sticky 補丁

  window.onscroll = function() {onWindowScroll()};
  const header = document.getElementById("pageHeader");
  const stickyOffset = 160;
  let prevOffset = 120
  function onWindowScroll() {
    let yOffset = window.pageYOffset
    if (yOffset > prevOffset ) {
      header.classList.add("isHide");
    } else {
      header.classList.remove("isHide");
      if (yOffset > stickyOffset) {
        header.classList.add("isSticky");
      } else {
        header.classList.remove("isSticky");
      }
    }
    prevOffset = yOffset
  }

});

function smoothScrollto (id) {
  let target = document.querySelector(`#${id}`)
  target.scrollIntoView({
    behavior: 'smooth'
  });
}

let lastKnownScrollPosition = 0;
let ticking = false;
let topAnchor = document.getElementById('goTop')
topAnchor?.addEventListener("click", ()=>{window.scrollTo({top: 0, behavior: 'smooth'});});

function whenScroll(scrollPos) {
  // 依據捲動位置進行某些操作
  if( scrollPos > 200 && !!topAnchor ) {
    topAnchor.classList.add('show')
  } else {
    topAnchor.classList.remove('show')
  }
}

window.addEventListener('load', AOS.refresh)

document.addEventListener('scroll', function(e) {
  lastKnownScrollPosition = window.scrollY;
  if (!ticking) {
    window.requestAnimationFrame(function() {
      whenScroll(lastKnownScrollPosition);
      ticking = false;
    });
    ticking = true;
  }
});
